import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard , ValidExpedition  } from './core/auth';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./views/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  /*{
    path: 'register',
    loadChildren: () => import('./views/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./views/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./views/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },*/
  {
    path: 'tracktrace',
    loadChildren: () => import('./views/pages/track-trace/track-trace.module').then(m => m.TrackTraceModule),
  },
  {
    path: 'tracktrace/:id',
    loadChildren: () => import('./views/pages/track-trace/track-trace.module').then(m => m.TrackTraceModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./views/pages/dashboard/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'client',
        loadChildren: './views/themes/geral/content/client/client.module#ClientModule'
      },
      {
        path: 'addresses',
        loadChildren: './views/themes/geral/content/addresses/addresses.module#AddressesModule'
      },
      {
        path: 'expedition/:id',
        loadChildren: './views/themes/geral/content/newexpedition/newexpedition.module#NewExpeditionModule',
      },
      {
        path: 'expedition/new',
        loadChildren: './views/themes/geral/content/newexpedition/newexpedition.module#NewExpeditionModule'
      },
      {
        path: 'listexpeditions',
        loadChildren: './views/themes/geral/content/listexpeditions/listexpeditions.module#ListExpeditionsModule'
      },
      {
        path: 'listexpeditions/:id',
        loadChildren: './views/themes/geral/content/listexpeditions/listexpeditions.module#ListExpeditionsModule'
      },
      {
        path: 'listcliexpeditions',
        loadChildren: './views/themes/geral/content/listcliexpeditions/listcliexpeditions.module#ListCliExpeditionsModule'
      },
      {
        path: 'listetqexpeditions',
        loadChildren: './views/themes/geral/content/listetqexpeditions/listetqexpeditions.module#ListEtqExpeditionsModule'
      },
      {
        path: 'listmaps',
        loadChildren: './views/themes/geral/content/listmaps/listmaps.module#ListMapsModule'
      },
      {
        path: 'listincidences',
        loadChildren: './views/themes/geral/content/listincidences/listincidences.module#ListIncidencesModule'
      },
      {
        path: 'listinvoices',
        loadChildren: './views/themes/geral/content/listinvoices/listinvoices.module#ListInvoicesModule'
      },
      {
        path: 'listinvexpeditions',
        loadChildren: './views/themes/geral/content/listinvexpeditions/listinvexpeditions.module#ListInvExpeditionsModule'
      },
    ]
  },
  {
    path: '**',
    loadChildren: () => import('./views/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
