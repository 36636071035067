import {Component, Inject, LOCALE_ID, OnInit, Renderer2} from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icTruck from '@iconify/icons-ic/local-shipping';
import icFolder from '@iconify/icons-ic/folder-open';
import icMyLocation from '@iconify/icons-ic/my-location';
import icLabelImportant from '@iconify/icons-ic/baseline-label-important';
import icLibraryBooks from '@iconify/icons-ic/library-books';
import { LayoutService } from '../@vex/services/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { TranslateService } from '@ngx-translate/core';
import {MediaMarshaller,PrintHook} from "@angular/flex-layout/core";
// Serviços
//import { ReservaService } from './services/Reservas.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vex';

  constructor(private configService: ConfigService,
              private styleService: StyleService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private router: Router,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private translate: TranslateService,
              private readonly printHook: PrintHook) {

    translate.addLangs(['es','en','pt']);
    translate.setDefaultLang('es');
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));

    this.router.events.subscribe((val: any) => {
      /*if(this.navigationService.items[i]['class'].includes('special-menu')) {
        if (profile == "FAT") {
          this.navigationService.items[i]['class'] = 'special-menu';
        } else {
          this.navigationService.items[i]['class'] = 'special-menu menu-hidden';
        }
      }*/
      let profile = localStorage.getItem('Profile');
      let mods = localStorage.getItem('Modules');
      if(profile && mods && this.navigationService.items && this.navigationService.items.length > 0) {
        for (let i = 0; i < this.navigationService.items.length; i++) {
          if(this.navigationService.items[i]['children']) {
            let access = false;
            for (let c = 0; c < this.navigationService.items[i]['children'].length; c++) {
              let module = this.navigationService.items[i]['children'][c].module;
              let modules = JSON.parse(atob(mods));
              let exists = modules.some(el => el.module === module);
              if(!exists) this.navigationService.items[i]['children'][c]['class'] = 'menu-hidden';
              if(exists && !access) access = true;
            }
            if(!access) this.navigationService.items[i]['class'] = 'menu-hidden';
          }
        }
      }
    });

    /**
     * Print Bug Fix
     * **/
    const printHookAny = this.printHook as any;
    const orig = printHookAny.collectActivations;
    printHookAny.collectActivations = (event) => {
      if (!printHookAny.isPrintingBeforeAfterEvent) {
        orig.bind(printHookAny, event)();
      }
    };
    
  }

  ngOnInit() {
    let lang = localStorage.getItem('lang');
    if(lang) this.translate.use(lang);
    this.setMenu();
    this.translate.onLangChange.subscribe((lang: any) => {
      this.setMenu();
    });
  }

  setMenu() {
    this.translate.get('init').subscribe((text: string) => {
      this.navigationService.items = [
        {
          type: 'link',
          label: this.translate.instant('MENU.DASHBOARD'),
          route: '/',
          icon: icLayers,
          class: '',
          routerLinkActiveOptions: {exact: true}
        },
        {
          type: 'dropdown',
          label: this.translate.instant('MENU.MAIN_EXPEDITIONS'),
          icon: icTruck,
          class: '',
          children: [
            {
              type: 'link',
              label: this.translate.instant('MENU.NEWEXPEDITION'),
              icon: icLabelImportant,
              class: '',
              route: '/expedition/new',
              module: 'newexpedition'
            },

            {
              type: 'link',
              label: this.translate.instant('MENU.LISTCLIEXPEDITIONS'),
              icon: icLabelImportant,
              class: '',
              route: '/listcliexpeditions',
              module: 'listcliexpeditions'
            },
            {
              type: 'link',
              label: this.translate.instant('MENU.LISTETQEXPEDITIONS'),
              icon: icLabelImportant,
              class: '',
              route: '/listetqexpeditions',
              module: 'listetqexpeditions'
            },
            {
              type: 'link',
              label: this.translate.instant('MENU.LISTMAPS'),
              icon: icLabelImportant,
              class: '',
              route: '/listmaps',
              module: 'listmaps'
            },
          ]
        },
        {
          type: 'dropdown',
          label: this.translate.instant('MENU.MAIN_TRACKTRANCE'),
          icon: icMyLocation,
          class: '',
          children: [
            {
              type: 'link',
              label: this.translate.instant('MENU.TRACKTRACE'),
              icon: icLabelImportant,
              class: '',
              route: '/listexpeditions',
              module: 'listexpeditions'
            },
            {
              type: 'link',
              label: this.translate.instant('MENU.LISTINCIDENCES'),
              icon: icLabelImportant,
              class: '',
              route: '/listincidences',
              module: 'listincidences'
            }
          ]
        },
        {
          type: 'dropdown',
          label: this.translate.instant('MENU.MAIN_BILLING'),
          icon: icLibraryBooks,
          class: 'special-menu',
          children: [
            {
              type: 'link',
              label: this.translate.instant('MENU.LISTINVEXPEDITIONS'),
              icon: icLabelImportant,
              class: '',
              route: '/listinvexpeditions',
              module: 'listinvexpeditions'
            },
            {
              type: 'link',
              label: this.translate.instant('MENU.LISTINVOICES'),
              icon: icLabelImportant,
              class: '',
              route: '/listinvoices',
              module: 'listinvoices'
            }
          ]
        },
        {
          type: 'dropdown',
          label: this.translate.instant('MENU.MAIN_MASTERDATA'),
          icon: icFolder,
          class: '',
          children: [
            {
              type: 'link',
              label: this.translate.instant('MENU.CLIENT'),
              icon: icLabelImportant,
              class: '',
              route: '/client',
              module: 'client'
            },
            {
              type: 'link',
              label: this.translate.instant('MENU.ADDRESSES'),
              icon: icLabelImportant,
              class: '',
              route: '/addresses',
              module: 'addresses'
            }
          ]
        }
      ];
    });
  }

}
