<div class="w-full h-full bg-pattern login-page" fxLayout="column" fxLayoutAlign="center center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
    <div class="px-1 lang-container">
      <button [matMenuTriggerFor]="languageMenu" matTooltip="Language"
              mat-icon-button class="lang-btn" type="button">
        <mat-icon [icIcon]="currentLanguage.flag"></mat-icon>
      </button>
    </div>
    <div class="p-6 pb-4 pt-8" fxLayout="column" fxLayoutAlign="center center">
      <div class="fill-current text-center">
        <!--<img class="w-16" src="assets/img/demo/logo.svg">-->
        <img class="w-56 dark" src="assets/img/demo/TN_Logo1.svg">
        <img class="w-56 white" src="assets/img/demo/TN_Logo1_White.svg">
      </div>
    </div>

    <div class="text-center mt-1">
      <h2 class="title m-0">{{'Login.WelcomeBack' | translate}}</h2>
      <h4 class="body-2 text-secondary m-0">{{'Login.SignInCredentials' | translate}}</h4>
    </div>

    <div [formGroup]="form" (keydown.Enter)="send()" class="p-6" fxLayout="column" fxLayoutGap="16px">
      <div fxFlex="auto" fxLayout="column">
        <mat-form-field fxFlex="grow">
          <mat-label>{{'Login.Email' | translate}}</mat-label>
          <input formControlName="email" matInput required>
          <mat-error *ngIf="form.get('email').hasError('required')">{{'Login.EmailNeeded' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>{{'Login.Password' | translate}}</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="{{'Login.ToggleVisibility' | translate}}" type="button">
            <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
            <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
          </button>
          <mat-hint>{{'Login.ClickEye' | translate}}</mat-hint>
          <mat-error *ngIf="form.get('password').hasError('required')">{{'Login.PasswordNeeded' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox class="caption" formControlName="rememberMe" (change)="toggleRememberMe()" color="primary">{{'Login.RememberMe' | translate}}</mat-checkbox>
        <!--<a [routerLink]="['/forgot-password']" class="caption">Forgot Password?</a>-->
      </div>

      <!--<button (click)="send()" color="primary" mat-raised-button type="button">
        {{'Login.SignIn' | translate}}
      </button>-->
      <mat-spinner-button
              [options]="spinnerButtonOptions"
              (btnClick)="send()">
      </mat-spinner-button>

      <p class="text-secondary text-center">
        <a [routerLink]="['/tracktrace']">{{ 'AUTH.SEARCH.TITLE' | translate }}</a>
      </p>

      <!--<p class="text-secondary text-center">
        {{'Login.HaveAccount' | translate}}<br/>
        <a [routerLink]="['/register']">{{'Login.CreateAccount' | translate}}</a>
      </p>-->
    </div>
  </div>
  <mat-menu #languageMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
    <button *ngFor="let language of languages" mat-menu-item (click)="setLanguage(language.lang)">
      <mat-icon [icIcon]="language.flag"></mat-icon>
      <span>{{language.name}}</span>
    </button>
  </mat-menu>
</div>
